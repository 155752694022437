<template>
    <div class="app-container-empty">
        <el-row>
            <el-col :style="{ width: 'calc(50% - 5px)' }">
                <el-card class="registryCard">
                    <div slot="header" class="cardHeader">
                        <el-row>
                            <el-col :span="16">
                                <el-button type="primary" icon="el-icon-plus" size="small" @click="createNode()">
                                    添加一级类别
                                </el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-input clearable :placeholder="$t('commons.searchPhrase')" maxlength="50"
                                    v-model="filterText">
                                </el-input>
                            </el-col>
                        </el-row>
                    </div>
                    <div>
                        <el-tree :data="data" :filter-node-method="filterNode" ref="tree">
                            <span class="custom-tree-node" slot-scope="{ node, data }" @mouseenter="mouseoverNode(node)"
                                @mouseleave="mouseoutNode(node)">
                                <span @click="clickNode(node, data)">{{ node.label }}</span>
                                <template v-if="node.data.show">
                                    <span>
                                        <el-button type="text" :style="{ marginLeft: '20px' }"
                                            v-if="node.data.threeBtnShow" @click="() => appendNode(node)">
                                            <i class="el-icon-circle-plus-outline"></i>
                                        </el-button>
                                        <el-button type="text" @click="() => removeNode(node, data)">
                                            <i class="el-icon-delete delete-btn"></i>
                                        </el-button>
                                    </span>
                                </template>
                            </span>
                        </el-tree>
                    </div>
                </el-card>
            </el-col>
            <el-col :style="{ width: 'calc(50% - 5px)', marginLeft: '10px' }">
                <el-card class="registryCard">
                    <el-form label-position="top" :model="channeldata.form" ref="channelForm"
                        :rules="channeldata.formRule">
                        <el-form-item label="描述名称" prop="name">
                            <el-input v-model="channeldata.form.name" :show-word-limit="true" autocomplete="off"
                                clearable :placeholder="$t('commons.pleaseInput')"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人" prop="contact">
                            <el-input v-model="channeldata.form.contact" :show-word-limit="true" autocomplete="off"
                                clearable :placeholder="$t('commons.pleaseInput')"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="phone">
                            <el-input v-model="channeldata.form.phone" :show-word-limit="true" autocomplete="off"
                                clearable :placeholder="$t('commons.pleaseInput')"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="channeldata.form.email" :show-word-limit="true" autocomplete="off"
                                clearable :placeholder="$t('commons.pleaseInput')"></el-input>
                        </el-form-item>
                        <el-form-item label="地址" prop="address">
                            <el-input v-model="channeldata.form.address" :show-word-limit="true" autocomplete="off"
                                clearable :placeholder="$t('commons.pleaseInput')"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="saveChanneData">
                                {{ $t("commons.save") }}
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { listToTree, handlelistToTree } from "@/utils/tree";
import {
    getAvenueList,
    addAvenueData,
    updataAvenueData,
    deleteAvenueData
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
export default {
    data() {
        return {
            filterText: "",
            treeRootId: 0,
            channeldata: {
                form: {
                    avenuesId: '',
                    parentId: this.treeRootId,
                    name: '',
                    contact: '',
                    phone: '',
                    email: '',
                    address: '',
                },
                formRule: {
                    name: [
                        { required: true, message: '必填项', trigger: ["blur", "change"] },
                    ]
                }
            },
            data: [],
            nodeCache: ''
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            let list = []
            getAvenueList().then(res => {
                list = res
                list = handlelistToTree(list, "avenuesId", "parentId");
                this.data = list
            })

        },
        mouseoverNode(node) {
            node.data.show = true;
        },
        mouseoutNode(node) {
            node.data.show = false;
        },
        clickNode(node, data) {
            console.log(node, 'node');
            console.log(data, 'data');
            this.channeldata.form.avenuesId = data.avenuesId
            this.channeldata.form.parentId = data.parentId
            this.channeldata.form.name = data.name
            this.channeldata.form.contact = data.contact
            this.channeldata.form.phone = data.phone
            this.channeldata.form.email = data.email
            this.channeldata.form.address = data.address
        },
        saveChanneData() {
            this.$refs.channelForm.validate((valid) => {
                if (valid) {
                    console.log(this.channeldata.form);
                    if (this.channeldata.form.avenuesId !== '') {
                        updataAvenueData(this.channeldata.form).then(res => {
                            this.updateTreeNode();
                            this.$message({
                                type: "success",
                                message: this.$t("message.saveSuccess"),
                            });
                        })
                            .catch((error) => {
                                console.log(`保存失败，原因 => ${error}`);
                            });
                    } else {
                        addAvenueData(this.channeldata.form).then(res => {
                            this.$message({
                                type: "success",
                                message: this.$t("message.saveSuccess"),
                            });
                            if(res.parentId !== 0) {
                                this.createSonNode(this.nodeCache, res);
                            } else {
                                this.getList()
                            }
                        })
                            .catch((error) => {
                                console.log(`保存失败，原因 => ${error}`);
                            });
                    }
                }
            })
        },
        //节点数据更新
        updateTreeNode() {
            console.log(this.$refs.tree.getCurrentNode(), ' this.$refs.tree.getCurrentNode()');
            this.$refs.tree.getCurrentNode().avenuesId = this.channeldata.form.avenuesId;
            this.$refs.tree.getCurrentNode().address = this.channeldata.form.address;
            this.$refs.tree.getCurrentNode().contact = this.channeldata.form.contact;
            this.$refs.tree.getCurrentNode().email = this.channeldata.form.email;
            this.$refs.tree.getCurrentNode().name = this.channeldata.form.name;
            this.$refs.tree.getCurrentNode().parentId = this.channeldata.form.parentId;
            this.$refs.tree.getCurrentNode().phone = this.channeldata.form.phone;
            this.$refs.tree.getCurrentNode().label = this.channeldata.form.name;
        },
        createSonNode(node, nodeChid) {
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            console.log(children, '当前级子节点所有数据');
            console.log(nodeChid, '要添加的子节点');
            // 获取当前节点的级别
            const currentNodeLevel = parent.level + 1; // 根节点级别

            // 遍历子节点数据，匹配父节点并添加到当前级子节点中
            children.forEach(currentChild => {
                if (nodeChid.parentId === currentChild.avenuesId) {
                    // 找到匹配的父节点，将子节点添加到该父节点下
                    if (!currentChild.children) {
                        currentChild.children = [];
                    }
                    // 添加 label 字段到 nodeChid 对象
                    nodeChid.label = nodeChid.name;
                    nodeChid.show = false;
                    nodeChid.children = [];

                    // 根据节点级别设置 threeBtnShow 属性
                    if (currentNodeLevel === 2) {
                        nodeChid.threeBtnShow = false;
                    } else {
                        nodeChid.threeBtnShow = true;
                    }
                    currentChild.children.push(nodeChid);
                }
            });


            console.log(children, '更新后的当前级子节点所有数据');

        },
        appendNode(node) {
            this.clearForm();
            console.log(node.data.avenuesId, 'node.data.avenuesId');
            this.channeldata.form.parentId = node.data.avenuesId;
            this.nodeCache = node
            this.createSonNode(node, '0');
        },
        createNode() {
            this.clearForm();
            this.channeldata.form.parentId = 0
        },
        clearForm() {
            this.channeldata.form.avenuesId = ''
            this.channeldata.form.parentId = ''
            this.channeldata.form.name = ''
            this.channeldata.form.contact = ''
            this.channeldata.form.phone = ''
            this.channeldata.form.email = ''
            this.channeldata.form.address = ''
        },
        removeNode(node, data) {
            console.log(node, 'node.isLeaf');
            if (node.isLeaf) {
                this.$confirm(
                    this.$t("message.deleteConfirm"),
                    this.$t("commons.warning"),
                    {
                        confirmButtonText: this.$t("commons.confirm"),
                        cancelButtonText: this.$t("commons.cancel"),
                        type: "warning",
                    }
                )
                    .then(() => {
                        deleteAvenueData({
                            avenuesId: data.avenuesId,
                        })
                            .then(() => {
                                // 成功提示
                                this.$message({
                                    type: "success",
                                    message: this.$t("message.deleteSuccess"),
                                });
                                //异步更新
                                const parent = node.parent;
                                const children = parent.data.children || parent.data;
                                const index = children.findIndex((d) => d.avenuesId === data.avenuesId);
                                children.splice(index, 1);
                            })
                            .catch((error) => {
                                console.log(`未删除，原因 => ${error}`);
                            });
                    })
                    .catch((error) => {
                        console.log(`未删除，原因 => ${error}`);
                    });
            } else {
                // 失败提示
                this.$message({
                    type: "info",
                    message: "有子节点，不可删除",
                });
            }

        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.toUpperCase().indexOf(value.toUpperCase()) !== -1;
        },
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },

}

</script>

<style scoped>
.registryCard {
    height: calc(100vh - 90px);
}

.registryCard>>>.el-card__header {
    padding: 6px 10px;
}

.registryCard>>>.el-card__body {
    overflow-y: auto;
    height: calc(100%);
}

.registryCard>>>.el-tree {
    margin-bottom: 50px;
}

.cardHeader {
    line-height: 34px;
    font-weight: 600;
}
</style>